<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="18">
        <DatePicker
          size="small"
          :clearable="false"
          :show-week-numbers="true"
          type="daterange"
          v-model="orderSchdule"
          @on-change="scheduleChange"
          style="width:400px"
          placement="top-start"
          placeholder="作业分布周期"
        ></DatePicker>
      </i-col>
      <i-col
        span="6"
        class="text-right"
      >
        <Button
          type="success"
          size="small"
          :disabled="selectedItems.length===0"
          @click="handleDownload"
        >下载作业列表</Button>
      </i-col>
    </Row>
    <Table
      size="small"
      stripe
      :data="taskList"
      :columns="tableColumns"
      @on-selection-change="handleSelection"
    ></Table>

    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="taskQuery.pageSize"
        show-total
        show-elevator
        show-sizer
        :page-size-opts="pagesizeSetting"
        @on-page-size-change='changePageSize'
        :current="taskQuery.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { commonMixins } from '@/assets/mixins/common'
import { GetDateStr, ParseDate } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'
import { gatherPage } from '@/api/msp/workday'

export default {
  mixins: [sysMixins, commonMixins],
  data () {
    return {
      showReport: true,
      orderSchdule: [],
      // assetId: 0,
      // assetArray: [],

      // stationArray: [],
      // stationId: 0,
      pagesizeSetting: [15, 50, 100, 200, 500],
      taskQuery: {
        pageNumber: 1,
        pageSize: 15,
        startDate: '',
        endDate: ''
      },
      lastPage: true,
      total: 0,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '时间', align: 'center', key: 'actionDate' },
        { title: '作业计划（站点）', align: 'center', key: 'totalNum' },
        { title: '供应商', align: 'center', key: 'supplierNum' },
        { title: '品牌', align: 'center', key: 'brandNum' },
        {
          title: '计划中/申请中/已确认/合计（站点）',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.draftNum} / ${params.row.applyNum} / ${params.row.confirmNum} / ${params.row.totalNum}`)
          }
        },
        {
          title: '推荐作业人数',
          align: 'center',
          key: 'recommendPersonQuantity',
          render: (h, params) => {
            return h('span', `${params.row.suggestMenNums}`)
          }
        },
        {
          title: '已派单/已完成/总计',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.assignNum} / ${params.row.finishNum} / ${params.row.totalTaskitemNum}`)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h(
              'a',
              {
                on: {
                  click: () => {
                    this.handleTaskDetail(params.row)
                  }
                }
              },
              '详情'
            )
          }
        }
      ],
      taskList: [],
      currentDate: '全部',
      tempSelectedItems: [],
      selectedItems: []
    }
  },
  computed: {
    startDate () {
      return this.$store.state.board.startDate
    },
    endDate () {
      return this.$store.state.board.endDate
    }
  },
  created () {
    if (this.startDate && this.endDate) {
      this.orderSchdule = [this.startDate, this.endDate]
    } else {
      if (!this.orderSchdule[0]) {
        this.orderSchdule = [GetDateStr(new Date(), 1), GetDateStr(new Date(), 8)]
      }
    }
    this.scheduleChange()
  },
  methods: {
    scheduleChange () {
      this.$store.commit('set_board_startDate', ParseDate(this.orderSchdule[0]))
      this.$store.commit('set_board_endDate', ParseDate(this.orderSchdule[1]))

      this.taskQuery.startDate = ParseDate(this.orderSchdule[0])
      this.taskQuery.endDate = ParseDate(this.orderSchdule[1])
      // 获取左侧品牌作业计划数据
      this.$store.dispatch('getBrandSummaryData', { startDate: this.taskQuery.startDate, endDate: this.taskQuery.endDate })
      // 获取右侧统计数据
      this.$store.dispatch('getWorkTypeData', { startDate: this.taskQuery.startDate, endDate: this.taskQuery.endDate })
      this.$store.dispatch('getSupplierStatData', { startDate: this.taskQuery.startDate, endDate: this.taskQuery.endDate })
      // 获取中间作业计划数据
      this.initData()
    },
    // 获取分页数据
    initData () {
      gatherPage(this.taskQuery).then(res => {
        if (res && !res.errcode) {
          this.taskList = res.list
          this.total = res.totalRow
          this.taskQuery.pageNumber = res.pageNumber
        } else {
          this.taskList = []
          this.total = 0
          this.taskQuery.pageNumber = 1
        }
      })
    },
    changePageSize (pageSize) {
      this.taskQuery.pageSize = pageSize
      this.taskQuery.pageNumber = 1
      this.initData()
    },
    handleSelection (selection) {
      this.selectedItems = []

      // 最终选中的所有taskItem
      const curSelection = selection.map(x => x.actionDate)
      this.handelPageSelections(this.tempSelectedItems, curSelection, this.taskQuery.pageNumber)
      this.tempSelectedItems.forEach(item => {
        this.selectedItems = this.selectedItems.concat(item.existSelections)
      })
    },
    // 展示站点任务详情
    handleTaskDetail (item) {
      this.$store.commit('set_board_actionDate', item.actionDate)

      this.setBottomComponent('planningDayDetail')
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'planningDayDetail', value: '计划日详情' })
      this.setLinkRouterMaps(['planningDayDetail', () => { this.setBottomComponent('planningDayDetail') }])
      this.setActivedTagName('planningDayDetail')
    },

    handlePageChanged (page) {
      this.taskQuery.pageNumber = page
      this.initData()
    },
    handleDownload () {
      if (this.selectedItems.length === 0) {
        this.$Notice.warning({ desc: '请至少选择一条数据' })
        return false
      }
      const filename = '作业计划列表.xlsx'
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/workday/download', { workDays: JSON.stringify(this.selectedItems) }, filename)
    }
  }
}
</script>
